import React, { Component } from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

class ScrollContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeEL: false,
    }

  }

  componentDidMount() {
    var isOverlapping = {
      inside: function (el1, el2) {
        var rect1 = el1.getBoundingClientRect();
        var rect2 = el2.getBoundingClientRect();

        return (
          ((rect2.top <= rect1.top) && (rect1.top <= rect2.bottom)) &&
          ((rect2.top <= rect1.bottom) && (rect1.bottom <= rect2.bottom)) &&
          ((rect2.left <= rect1.left) && (rect1.left <= rect2.right)) &&
          ((rect2.left <= rect1.right) && (rect1.right <= rect2.right))
        );
      }
    };

    const dot = document.getElementById('scroll-slide-dot');
    const slides = document.getElementsByClassName('scroll-slide');

    window.addEventListener("scroll", () => {
      for (let i = 0; i < slides.length; i++) {
        if (isOverlapping.inside(dot, slides[i])) {
          this.setState({activeEL: slides[i].id })
        }
      }
    })

		// Adjust the bottoms of scroll container elements
    const scroller = document.getElementById('scroll-slide-container')
		const scrollImages = document.getElementById('scroll-slide-images')
    const slideElements = document.getElementsByClassName("scroll-slide")
    var lastSlide = slideElements[slideElements.length - 1]

    scroller.style.height = scroller.clientHeight - lastSlide.clientHeight + 'px'
    scroller.style.marginBottom = lastSlide.clientHeight + 'px'
		scrollImages.style.bottom = '-' + (Number(lastSlide.clientHeight) + 100) + 'px'
  }

  render() {
    const slideImages = this.props.slides.map((item, key) => (
      <img
        className={this.state.activeEL === "scroll-slide-" + key ? "show" : "hideme"}
        key={key}
        src={item.fields.slideImage["en-US"].fields.file["en-US"].url}
        alt=""
      />
    ))

    const slideElements = this.props.slides.map((item, key) => (
      <div id={"scroll-slide-" + key}  className="scroll-slide" key={key}>
        <div className="scroll-slide-content">
					<img
		        key={key}
		        src={item.fields.mobileImage["en-US"].fields.file["en-US"].url}
		        alt=""
		      />
          <h2 data-slide-number={key}>
            {item.fields.title["en-US"]}
          </h2>
          {documentToReactComponents(item.fields.description["en-US"])}
        </div>
      </div>
    ))

    return (
      <div id="temp-scroll-demo" className="content">
        <div>
          <div id="scroll-slide-container" className="scroll-slide-container">
            <div id="scroll-slide-images">{slideImages}</div>
            <div className="scroll-slide-divider"></div>
            <div id="scroll-slide-dot" className="scroll-slide-dot"></div>
            {slideElements}
          </div>
          <div className="disclaimer">
            <small><em>{this.props.disclaimer}</em></small>
          </div>
        </div>
        
      </div>
    )
  }
}

export default ScrollContainer
