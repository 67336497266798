import React, { Component } from "react"

// Bootstrap components
import Button from "react-bootstrap/Button"
import Navbar from "react-bootstrap/Navbar"
import NavDropdown from "react-bootstrap/NavDropdown"
import { Nav } from "react-bootstrap"

// Other assets
import PersosaLogo from "../assets/images/Logo-White.svg"
import ToggleIcon from "../assets/images/icon-menu.svg"

class Navigation extends Component {


  render() {
		// const loginLink = this.props.loginLink
		// const contactLink = this.props.contactLink

    const getNavbarToggleIcon = () => {
      return <img width="50" src={ToggleIcon} alt="logo" />
    }

    const menus = this.props.menus.map((link, key) => {
      // if the menu link is supposed to link to a page
      if (link.titleLink && !link.links) {
        return (
          <Nav.Link key={key} href={link.titleLink}>
            {link.title}
          </Nav.Link>
        )
      }

      // if the menu link contains an array of links, make it a dropdown
      if (link.links) {
        return (
          <NavDropdown key={key} title={link.title}>
            {link.links.map((item, key) => {
              return (
                <NavDropdown.Item key={key} href={item.url}>
                  {item.title}
                </NavDropdown.Item>
              )
            })}
          </NavDropdown>
        )
      } else {
        return <></>
      }
    })

    return (
      <Navbar variant={this.props.color} expand="lg">
        <Navbar.Brand href="/">
          <img
            src={PersosaLogo}
            className="d-inline-block align-top"
            alt="Persosa logo"
          />
        </Navbar.Brand>

				<Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          children={getNavbarToggleIcon()}
				/>

				<Navbar.Collapse id="responsive-navbar-nav">
	        <Nav className="mx-auto">{menus}</Nav>
	        <div className="nav-login-contact d-lg-flex">
						{/*
		          <Nav>
		            <Nav.Link href={loginLink}>Login</Nav.Link>
		          </Nav>
						*/}
	          <Button href="/contact" variant="outline-light">Contact Us</Button>
	        </div>
				</Navbar.Collapse>
      </Navbar>
    )
  }
}

export default Navigation
