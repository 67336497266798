import React from 'react';
import PropTypes from 'prop-types';

CalendlyEmbed.propTypes = {
    calendlyLink: PropTypes.string.isRequired,
};

function CalendlyEmbed(props) {
    React.useEffect(() => {
        loadScript('https://assets.calendly.com/assets/external/widget.js');
    });

    return (
				<div className="calendly-inline-widget" data-url={props.calendlyLink}></div>
    );
}
function loadScript(src) {
    const tag = document.createElement('script');
    tag.async = false;
    tag.src = src;
    const body = document.getElementsByTagName('body')[0];
    body.appendChild(tag);
}
export default CalendlyEmbed;