import React from 'react';
import PropTypes from 'prop-types';

//remove annoying custom stream tag error
const realError = console.error;
console.error = (...x) => {
    if (x && x[0] && x[0].startsWith('Warning: The tag <%s> is unrecognized in this browser. ' +
        'If you meant to render a React component, start its name with an uppercase letter.')) {
        return;
    }
    realError(...x);
};

CloudflareStream.propTypes = {
    videoId: PropTypes.string.isRequired,
    videoToken: PropTypes.string,
		autoPlay: PropTypes.bool,
		loop: PropTypes.bool,
		mute: PropTypes.string,
		controls: PropTypes.bool
};

function CloudflareStream(props) {
    React.useEffect(() => {
        loadScript('https://embed.videodelivery.net/embed/r4xu.fla9.latest.js?video=' + props.videoId);
    }, [props.videoId]);
    const restProps = {...props};
    delete restProps.videoToken;
    delete restProps.videoId;
		var el = "";
		if (props.controls === false) {
    	el = <stream className="cloud-flare-stream" preload="true" autoPlay={props.autoPlay} loop={props.loop} mute={props.mute} src={props.videoToken || props.videoId} {...restProps} />
    } else {
			el = <stream className="cloud-flare-stream" controls preload="true" autoPlay={props.autoPlay} loop={props.loop} mute={props.mute} src={props.videoToken || props.videoId} {...restProps} />
		}
    return (
        el
    );
}

function loadScript(src) {
    const tag = document.createElement('script');
    tag.async = false;
    tag.src = src;
    const body = document.getElementsByTagName('body')[0];
    body.appendChild(tag);
}
export default CloudflareStream;