import React, { Component } from "react"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import IconBox from "../components/IconBox"
import Button from "../components/Button"
import TeamMember from "../components/TeamMember"
import ScrollContainer from "../components/ScrollContainer"
import CloudflareStream from "./CloudflareStream"
import CalendlyEmbed from "./CalendlyEmbed"
import SupportForm from "../components/SupportForm"

class Markdown extends Component {
  render() {
    return (
      <>
        {this.props.content &&
          this.props.content.json &&
          documentToReactComponents(this.props.content.json, {
            renderMark: {
              bold: text => <b>{text}</b>,
            },
            renderNode: {
              [BLOCKS.EMBEDDED_ENTRY]: node => {
                if (
                  node &&
                  node.data &&
                  node.data.target &&
                  node.data.target.sys &&
                  node.data.target.sys.contentType &&
                  node.data.target.sys.contentType.sys &&
                  node.data.target.sys.contentType.sys.id
                ) {
                  const entryId = node.data.target.sys.contentType.sys.id
                  if (entryId === "button") {
                    const { link, label, openLinkIn } = node.data.target.fields

                    if (label) {
                      return <Button link={link} label={label} openLinkIn={openLinkIn} />
                    }
                  }
                  if (entryId === "iconBox") {
                    // node is a single instance of an iconBox
                    return <IconBox data={node.data.target.fields} />
                  }
                  if (entryId === "teamMember") {
                    return <TeamMember data={node.data.target.fields} />
                  }
                  if (entryId === "scrollContainer") {
                    return <ScrollContainer slides={node.data.target.fields.scrollSlides['en-US']} disclaimer={node.data.target.fields.disclaimer['en-US']} />
                  }
									if (entryId === "cloudflareVideo") {
                    return <CloudflareStream videoId={node.data.target.fields.videoId['en-US']} />
                  }
									if (entryId === "calendlyEmbed") {
                    return <CalendlyEmbed calendlyLink={node.data.target.fields.calendlyLink['en-US']} />
                  }

                  if (entryId === "hubSpotEmbed") {
                    const rawHtml = node.data.target.fields.code['en-US']

                    var str = rawHtml;
                    var n = str.search("hbspt.forms.create");

                    var res = str.substring(n, str.length);
    
                    var regExp = /\(([^)]+)\)/;
                    var matches = regExp.exec(res);
                    const result = matches[1].replace(/(\r\n|\n|\r)/gm, "");
                    const formattedResult = result.replace(/[{}]/g, "")

          
                    var x = formattedResult.split(",");

                    var splitPortal = x[0].split(":");
                    const portalId = splitPortal[1].replace(/[""]/g, "")

                    var splitFormId = x[1].split(":");
                    const formId = splitFormId[1].replace(/[""]/g, "")

                    return <SupportForm portalId={portalId} formId={formId} />
                  }

                } else {
                  return ""
                }
              },
              [BLOCKS.EMBEDDED_ASSET]: node => {
                if (
                  node &&
                  node.data &&
                  node.data.target &&
                  node.data.target.fields &&
                  node.data.target.fields.file
                ) {
                  const assetUrl = node.data.target.fields.file["en-US"].url
                  return <img alt="" src={assetUrl} />
                } else {
                  return ""
                }
              },
            },
          })}
      </>
    )
  }
}

export default Markdown
