import React, { Component } from "react"

// Bootstrap components
import BSButton from "react-bootstrap/Button"

class Button extends Component {
  render() {
    const label = this.props.label["en-US"]
    const link = this.props.link ? this.props.link['en-US'] : '';
    const openLinkIn = () => {
      // checking if openLinkIn is null
      if (this.props.openLinkIn) {
        return this.props.openLinkIn['en-US'] === "New Tab" ? "_blank" : "_self"
      } else {
        // opens tab in new window if openLinkIn is null
        return "_blank"
      }
    }

    return (
      <div className="text-center">
        <BSButton variant="primary" href={link} target={openLinkIn()}>
          {label}
        </BSButton>
      </div>
    )
  }
}

export default Button
