import React, { Component } from "react"

// Bootstrap components
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

// Other assets
import PersosaLogo from "../assets/images/Logo-White.svg"

class Footer extends Component {

  render() {
    const links = links =>
      links ? links.map((link, key) => (
				<li className="list-item" key={key}>
	        <a href={link.url}>
	          {link.title}
	        </a>
				</li>
      )) : [];

    const menus = this.props.footer.menus.map((item, key) => (
      <Col lg="2" key={key}>
        <h5>{item.title}</h5>
				<ul className="list-unstyled">
        	{links(item.links)}
				</ul>
      </Col>
    ))

    return (
      <div className="footer">
        <Container>
          <Row>
            <Col lg="4" className="mr-auto">
              <img
                alt="Persosa"
                className="footer-logo"
                src={PersosaLogo}
              />
						<p className="colophon">{this.props.footer.colophon}</p>
            </Col>
            {menus}
          </Row>
        </Container>
      </div>
    )
  }
}

export default Footer
