import React, { Component } from "react";

class IconBox extends Component {
  render() {
    const { title, description, iconImage } = this.props.data
    return (
    <div className="icon-box">
      <img src={iconImage['en-US'].fields.file['en-US'].url} alt="" />
      <h4>{title['en-US']}</h4>
      <p>{description['en-US']}</p>
    </div>
    )
  }
}

export default IconBox

