import React, { Component } from "react";

// Bootstrap components
import Card from "react-bootstrap/Card"

// Other assets
import LinkedInIcon from "../assets/images/icon-linkedin.svg";

var classNames = require("classnames")


class TeamMember extends Component {
  render() {
    const { bio, linkedIn, name, photo, title, vip } = this.props.data

    const url = photo['en-US'].fields.file['en-US'].url

		const postImageStyle = (url) => {
			if ( url ) {
	      return {
	        backgroundImage: `url(${url})`,
	      }
			} else {
        return ""
      }
		};

		var cardClass = classNames({
      'team-card': true,
      'is-vip': vip['en-US'],
		});

    return (
    <Card className={cardClass}>
			<div className="card-image" style={postImageStyle(url)}></div>

      <Card.Body>
        <h4>{name['en-US']}</h4>
        <div className="team-title">{title['en-US']}</div>
				{ linkedIn['en-US'] &&
					<a href={linkedIn['en-US']} className="team-social" target="_blank" rel="noopener noreferrer">
						<img src={LinkedInIcon} alt="LinkedIn" />
					</a>
				}
        {vip['en-US'] ? <p>{bio['en-US']}</p> : ''}
      </Card.Body>
    </Card>
    )
  }
}

export default TeamMember

