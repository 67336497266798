import React, { Component } from "react"
import PropTypes from "prop-types"

// Bootstrap components
import Container from "react-bootstrap/Container"

// Persosa components
import Navigation from "../components/Navigation"

// Other assets
import "../assets/scss/App.scss"

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false,
    }
  }

  // Adds an event listener when the component is mount.
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

    // Hide or show the menu.
    handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const visible = currentScrollPos > 200;

      this.setState({
        visible,
      });
    };

  render() {
    const children = this.props.children;
    const navbar = this.props.navbar;

    return (
      <div>
        <Container fluid className={`sticky-nav ${this.state.visible ? "bg-dark sticky-nav-visible" : "bg-dark"}`}>
          <Navigation color="dark" menus={navbar.menus} />
        </Container>

        <main>{children}</main>
      </div>
    )
  }
}

App.propTypes = {
  children: PropTypes.node.isRequired,
}

export default App
