import React, { Component } from "react";
import HubspotForm from 'react-hubspot-form';
var classNames = require("classnames");

class SupportForm extends Component {
  render() {
    const { portalId, formId } = this.props;
    return (
      <HubspotForm portalId={portalId} formId={formId} loading={<div>Loading...</div>} />
    )
  }
}

export default SupportForm
